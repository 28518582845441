exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* 35% transparent version of motion-primary */ /* 15% transparent version of motion-primary */ /* opt-in theme overrides */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* #0FBD8C */ /* #FF8C1A */ /* #FFB366 */ /* #FF8C1A */ /* 35% transparent version of extensions-primary */ /* opaque version of extensions-transparent, on white bg */ /* lighter than motion-primary */ .load-extension_code_18JAZ,\n.load-extension_unsandboxed-container_3wUNS,\n.load-extension_unsandboxed-warning_3AXrs,\n.load-extension_sandboxed_2q4uS {\n    margin: 8px 0;\n} .load-extension_unsandboxed-container_3wUNS {\n    display: flex;\n    align-items: center;\n} .load-extension_unsandboxed-checkbox_3F7r1 {\n    margin-right: 8px;\n} .load-extension_unsandboxed-warning_3AXrs {\n    padding: 0.5rem;\n    border-radius: 0.25rem;\n    background-color: rgba(255, 81, 81, 0.25);\n    border: 1px solid red;\n}\n", ""]);

// exports
exports.locals = {
	"code": "load-extension_code_18JAZ",
	"unsandboxed-container": "load-extension_unsandboxed-container_3wUNS",
	"unsandboxedContainer": "load-extension_unsandboxed-container_3wUNS",
	"unsandboxed-warning": "load-extension_unsandboxed-warning_3AXrs",
	"unsandboxedWarning": "load-extension_unsandboxed-warning_3AXrs",
	"sandboxed": "load-extension_sandboxed_2q4uS",
	"unsandboxed-checkbox": "load-extension_unsandboxed-checkbox_3F7r1",
	"unsandboxedCheckbox": "load-extension_unsandboxed-checkbox_3F7r1"
};